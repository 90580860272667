<script>
import axios from "axios";
import store from "@/store";
import router from "@/router";
import Footer from "@/components/navigation/Footer";

export default {
  name: "confirm",

  components: {
    Footer,
  },

  data: () => ({
    loading: true,

    logoImage: require("@/assets/images/logos/logo-icon.svg"),
    logoText: require("@/assets/images/logos/logo-text.svg"),

    uuid: "",
  }),

  async beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getData();
    });
  },

  methods: {
    async getData() {
      this.loading = true;
      try {
        await this.getRoute();
        await axios.post(
          `https://inn-api.powerit.dev/api/v1/online_requests/confirm_by_patient/${store.getters["localization/getCurrent"]}`,
          {
            uuid: `${this.uuid}`,
          }
        );
        this.loading = false;
      } catch (e) {
        await router.push("/online-requests/confirm-error");
        const response = e.response.data;
        await store.dispatch("alert/showError", response.message);
      }
    },
    async getRoute() {
      const [, , , uuid] = this.$route.path.split("/");
      this.uuid = uuid;
    },
  },
};
</script>

<template>
  <div id="home" class="fill-height">
    <div class="pt-10">
      <v-container class="my-15">
        <v-card>
          <v-card-text>
            <div class="text-center">
              <v-icon color="innirm" size="128"> mdi-emoticon </v-icon>
            </div>
            <div class="mx-15 mt-5">
              <p class="text-body-1 font-weight-bold mb-10">
                {{ $t("response.success.title") }}
              </p>
              <p class="text-body-1 mb-7">
                {{ $t("response.success.text") }}
              </p>
              <p class="text-body-1 font-weight-bold">
                {{ $t("response.success.team") }}
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-container>
    </div>
    <Footer />
  </div>
</template>

<style scoped lang="scss">
.full-height {
  height: 100% !important;
}
.full-width {
  width: 100% !important;
}
@media (min-width: 1904px) {
  .container {
    max-width: 1185px !important;
  }
}
</style>
